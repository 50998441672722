define("smile-developers/templates/components/app/oauth-tab/tokens-section-description", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "AqEft+7/",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,0],[12],[1,\"Use these keys to authenticate your account with Smile.\"],[13],[1,\"\\n\\n  \"],[10,0],[12],[1,\"\\n    \"],[1,[28,[35,1],null,[[\"external\",\"text\",\"url\"],[true,\"Learn more about switching client secrets\",\"https://dev.smile.io/guides/integrations/integration-auth/oauth-rotation\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"polaris-stack\",\"polaris-link\"]]",
    "moduleName": "smile-developers/templates/components/app/oauth-tab/tokens-section-description.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});