define("smile-developers/templates/resources", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "DpygvqIV",
    "block": "[[[6,[39,0],null,[[\"title\"],[\"Resources\"]],[[\"default\"],[[[[6,[39,1],null,null,[[\"default\"],[[[[6,[30,1,[\"annotatedSection\"]],null,[[\"title\"],[\"Manager\"]],[[\"default\"],[[[[1,\"      \"],[1,[28,[35,2],null,[[\"manager\"],[[33,3]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,1,[\"annotatedSection\"]],null,[[\"title\"],[\"Useful information\"]],[[\"default\"],[[[[1,\"      \"],[1,[34,4]],[1,\"\\n\"]],[]]]]]],[1]]]]]],[]]]]]],[\"layout\"],false,[\"polaris-page\",\"polaris-layout\",\"manager-profile-card\",\"manager\",\"resources/useful-information\"]]",
    "moduleName": "smile-developers/templates/resources.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});