define("smile-developers/utils/partner-resources", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    generalResources: [{
      text: 'Merchant Help Center',
      url: 'https://help.smile.io/'
    }, {
      text: 'Shopper / Customer Help Center',
      url: 'https://faq.smile.io/'
    }],
    technicalDocumentation: [{
      text: 'Smile.io Developer Hub',
      url: 'https://dev.smile.io/'
    }, {
      text: 'Technical Guides',
      url: 'https://dev.smile.io/guides'
    }, {
      text: 'API Reference',
      url: 'https://dev.smile.io/api'
    }, {
      text: 'API Explorer',
      url: 'https://dev.smile.io/api-runner/smile/api'
    }, {
      text: 'JavaScript SDK',
      url: 'https://dev.smile.io/js'
    }]
  };
  _exports.default = _default;
});